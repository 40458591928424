<template>

<app-content :pending="true">

</app-content>

</template>

<script>

export default {

}

</script>

<style scoped>

</style>